import React from 'react'

const Footer = () => {
    return (
        <div>
            <br/>
            <hr/>
            <p className="footer-text">Made with ♥️ by Tejaswi<br/>
                <br/>
                <small>All rights reserved © 2022-24 Tejaswi Chaudhari</small><br/>
                <small>Illustrations by undraw.co</small>
            </p>
        </div>
    )
}

export default Footer
